import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN } from "../components/seo";
import { translations } from "../locales/birthday.js";
import EventsTypes from "../components/eventsTypes";
import background from "../assets/img/eventsTypes/birthday.svg";

const Birdthday = () => {
  return (
    <Layout page="birthday" language="en">
      <main className=" pricing-page-container container">
        <EventsTypes
          background={background}
          text={translations.en.text}
          title={translations.en.title}
          textButton={translations.en.button}
          language={"en"}
        />
      </main>
    </Layout>
  );
};

export default Birdthday;
export const Head = () => <SeoEN title="Digital Birthday Invitations Online | Create unique birthday invitations with Kolibird" description="Celebrate your birthday with personalized digital invitations made in Kolibird. Forget about paper and send your invitations in an easy and eco-friendly way. Sign up now at Kolibird and start designing your unique birthday invitations." hrefLangs={[{href: "https://kolibird.app/invitaciones-digitales-cumpleanos/", lang: "es"}, {href: "https://kolibird.app/en/digital-birthday-invitations/", lang: "en"}]}/>;
